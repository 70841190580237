import React, { useEffect, memo, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import Tabs from '@common/components/tabs';
import { Overview } from '@common/components/overview';
import { PureList } from '@common/components/list';
import { Button } from '@common/components/button';
import Confirm from '@common/components/confirm-button';
import { Select } from '@common/components/form/inputs/select';
import Spinner from '@common/components/spinner';
import PointsLine from '@common/components/points-line/points-line';
import { FormAnswer } from '../answer';
import SubmissionComments from './submission-comments';

import { combineClassNames } from '@common/utils/combineClassNames';
import type { ResponseStatusActivity, SubmissionStatus } from '../../types';
import { User } from '@common/types/objects';
import { useQueryParam } from '@common/hooks/url';


const ResponseAnswers = memo(({
  submission,
  form,
  deleteSubmission,
  submissionsPath,
  updateSubmissionStatus
}: any) => {
  const history = useHistory();
  const { t } = useTranslation();

  const header = submission?.user ?
    'forms:form_submission_title' :
    'survey:survey_response_title_anonymous';

  const responseStatusEnabled = form?.response_statuses?.length > 0;

  const handleDelete = async () => {
    if (!form || !submission) return;

    await deleteSubmission(form.id, submission.id);
    const path = submissionsPath || `/admin/forms/${form.id}/edit/submissions`;
    history.replace(path);
  };

  const onChangeStatus = (status: SubmissionStatus) => {
    if (!submission || !form) return;

    updateSubmissionStatus(form?.id, submission.id, status);
  };

  return (
    <PureList
      containerClassName="Response__Answers"
      items={submission.answers}
      header={(
        <div className="Response__Header">
          <h1 className="fs-exclude">
            { t(header, { name: submission.user?.full_name }) }
          </h1>
          {
            responseStatusEnabled && (
              <Select
                clearable={false}
                options={(form.response_statuses || []) as SubmissionStatus[]}
                labelKey="name"
                valueKey="id"
                value={submission.response_status?.id}
                onChange={onChangeStatus}
                OptionComponent={({ option, isSelected, onSelect }) => (
                  <div
                    role="option"
                    className={
                      combineClassNames('SubmissionStatus', {
                        'SubmissionStatus--selected': isSelected
                      })
                    }
                    onClick={() => onSelect(option)}
                  >
                    <div
                      className="SubmissionStatus__Dot"
                      style={{ backgroundColor: option.color }}
                    />
                    {option.name}
                  </div>
                )}
                valueRenderer={(option) => (
                  <div className="SubmissionStatus">
                    <div
                      className="SubmissionStatus__Dot"
                      style={{ backgroundColor: option.color }}
                    />
                    {option.name}
                  </div>
                )}
              />
            )
          }

          <Confirm
            danger
            title={t('forms:confirm_delete_submission')}
            onConfirm={handleDelete}
          >
            <Button icon="delete" type="warning" size="large" />
          </Confirm>
        </div>
      )}
      renderRow={FormAnswer}
      rowProps={{
        timezone: submission.timezone,
      }}
    />
  );
});

const submissionTabsItem = memo(({ title, active, onClick }: any) => {
  let className = 'submissionTabsItem';
  if (active) {
    className += ' submissionTabsItem--active';
  }
  return (
    <div role="button" onClick={onClick} className={className}>
      { title }
    </div>
  );
});

const ActivityLabel = memo(({ children, date }: any) => {
  return (
    <div className="ActivityLabel">
      <div>{ children }</div>
      <div className="date">{ moment(date).format('ll') }</div>
    </div>
  );
});

const Activity = memo(({ submission, submissionMeta }: any) => {
  const points = useMemo(() => {
    const { users } = submissionMeta.related;
    const activities = submission.response_status_activities;

    const descSortedPoints = activities?.toSorted((
      a: ResponseStatusActivity,
      b: ResponseStatusActivity
    ) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (dateA < dateB) return 1;
      if (dateA === dateB) return 0;
      return -1;
    })?.map((res: ResponseStatusActivity) => {
      const user = users.find((u: User) => u.id === res.user_id);
      return {
        id: res.id,
        color: res.new_status.color,
        element: (
          <ActivityLabel date={res.created_at}>
            <Trans
              i18nKey="forms:status_changed_to"
              values={{
                status: res.new_status.name,
                userName: user.full_name
              }}
              components={[<b />, <b />]}
            />
          </ActivityLabel>
        )
      };
    });

    return [
      ...(descSortedPoints || []),
      {
        id: -1,
        color: '#E5E7EB',
        element: (
          <ActivityLabel date={submission.created_at}>
            <Trans
              i18nKey="forms:submission_has_been_created"
              components={[<b />]}
            />
          </ActivityLabel>
        )
      }
    ];
  }, [submission, submissionMeta]);

  return <PointsLine points={points} />;
});

const statusActivitiesIntroductionDate = new Date('05/19/2023');

export default function Submission(props: any) {
  // console.log("debug Submission props", props);
  const {
    match: { params },
    form,
    submission,
    submissionMeta,
    fetchSubmission,
    updateSubmissionStatus,
    deleteSubmission,
    submissionsPath,
    organisationId
  } = props;

  const { t } = useTranslation();

  const { id, submissionId } = params;
  useEffect(() => {
    fetchSubmission(id, submissionId);
  }, [id, submissionId]);

  const showActivityTab = (
    submission?.response_status_activities?.length > 0 ||
    submission?.created_at ?
      (new Date(submission.created_at) > statusActivitiesIntroductionDate) :
      false
  );

  const showComments = useQueryParam('showComments');
  const initialTabIndex = showComments ? 1 : 0;

  return (
    <Overview.Content className="Survey__Response">
      {
        submission && form ?
          (
            <Tabs
              TabsHeaderItem={submissionTabsItem}
              className="submissionTabs"
              initialTabIndex={initialTabIndex}
            >
              <Tabs.Item title={t('common:answers')}>
                <ResponseAnswers
                  submission={submission}
                  form={form}
                  deleteSubmission={deleteSubmission}
                  submissionsPath={submissionsPath}
                  updateSubmissionStatus={updateSubmissionStatus}
                />
              </Tabs.Item>
              <Tabs.Item title={t('core:comments')}>
                <SubmissionComments
                  organisationId={organisationId}
                  submissionId={submissionId}
                />
              </Tabs.Item>
              {
                showActivityTab && (
                  <Tabs.Item title={t('common:activity')}>
                    <Activity
                      submission={submission}
                      submissionMeta={submissionMeta}
                    />
                  </Tabs.Item>
                )
              }
            </Tabs>
          ) :
          (
            <Spinner size="large" centered />
          )
      }
    </Overview.Content>
  );
}
